import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";

const Contact = () => {
  useEffect(() => {
    // When the page changes, scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);

  const form = useRef();
  const [error, setError] = useState({});

  const validatePhoneNumber = (phoneNumber) => {
    // Add your phone number validation logic here
    return /^[0-9]{10}$/.test(phoneNumber);
  };

  const validateEmail = (email) => {
    // Add your email validation logic here
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setError((prevError) => ({ ...prevError, [name]: "" }));

    if (name === "from_phoneNumber" && !validatePhoneNumber(value)) {
      setError((prevError) => ({
        ...prevError,
        [name]: "Invalid phone number",
      }));
    }

    if (name === "from_email" && !validateEmail(value)) {
      setError((prevError) => ({
        ...prevError,
        [name]: "Invalid email address",
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const phoneNumber = form.current.from_phoneNumber.value;
    const email = form.current.from_email.value;

    // Validation
    if (!validatePhoneNumber(phoneNumber)) {
      setError((prevError) => ({
        ...prevError,
        from_phoneNumber: "Invalid phone number",
      }));
      return;
    }

    if (!validateEmail(email)) {
      setError((prevError) => ({
        ...prevError,
        from_email: "Invalid email address",
      }));
      return;
    }

    // Replace these values with your EmailJS user ID, service ID, and template ID
    const publicKey = "WqVfDiwGemQ1xzGfC";
    const serviceID = "service_cp528ve";
    const templateID = "template_cnhd80o";

    // Send the email using EmailJS
    emailjs
      .sendForm(serviceID, templateID, form.current, publicKey)
      .then((result) => {
        console.log(result.text);
        // Handle success (e.g., show a success message)
        alert("Message sent successfully!");
      })
      .catch((error) => {
        console.error(error.text);
        // Handle error (e.g., show an error message)
        alert("Error sending message. Please try again later.");
      });
  };

  return (
    <div className="contact">
      <form ref={form} onSubmit={handleSubmit}>
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="from_name"
          placeholder="Your name"
          required
          onChange={handleInputChange}
        />
        <label htmlFor="phone">Phone:</label>
        <input
          type="tel"
          id="phone"
          name="from_phoneNumber"
          placeholder="Your phone number"
          required
          onChange={handleInputChange}
        />
        {error.from_phoneNumber && (
          <p className="error">{error.from_phoneNumber}</p>
        )}

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="from_email"
          placeholder="Your email"
          required
          onChange={handleInputChange}
        />
        {error.from_email && <p className="error">{error.from_email}</p>}

        <label htmlFor="message">Message:</label>
        <textarea
          id="message"
          name="message"
          placeholder="Your message"
          required
        ></textarea>

        <button className="button" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
};

export default Contact;
