import React, { useEffect } from "react";
import "./Services.css";
import services1 from "./images/services1.jpg";
import services2 from "./images/services2.jpg";
import services3 from "./images/services3.jpg";
import AOS from "aos";
import "aos/dist/aos.css";

const Services = () => {
  useEffect(() => {
    AOS.init({duration:1000});
  }, []);
  return (
    <div className="services-container">
      <div className="services-card margin-right" data-aos="flip-right">
        <img src={services1} alt="Service 1" />
        <h2>House Keeping Service</h2>
        <p>
          Our housekeeping services pledge to provide unparalleled cleanliness
          and hygiene in residential and commercial spaces, with a team of
          professionals committed to maintaining consistency. Whether for homes,
          offices, hotels, or healthcare facilities, their services aim to
          elevate expectations of cleanliness and service excellence.
        </p>
      </div>

      <div className="services-card margin-left" data-aos="flip-left">
        <img src={services2} alt="Service 2" />
        <h2>Stewarding Solutions</h2>
        <p>
          Stewarding Solutions excels in seamless event setup and post-event
          cleanup, offering expert handling of dishwashing, kitchen maintenance,
          and support for culinary operations. Their detail-oriented staff
          maintains high standards of cleanliness throughout their services. .
        </p>
      </div>

      <div className="services-card margin-right" data-aos="flip-right">
        <img src={services3} alt="Service 3" />
        <h2>Gardening Delight</h2>
        <p>
          Specializing in transforming outdoor spaces into lush and vibrant
          havens, our skilled gardening team offers comprehensive services,
          including landscape design, planting, pruning, and ongoing
          maintenance. We provide tailored gardening plans to meet aesthetic
          preferences and specific maintenance requirements, ensuring
          personalized and expert care for outdoor environments.
        </p>
      </div>
    </div>
  );
};

export default Services;
