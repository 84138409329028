import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import Testimonials from "./components/Testimonials";
import Demo from "./components/Demo";
import Footer from "./components/Footer";
import Contact from "./components/Contact";
import Services from "./components/Services";

function Homepage({ setPage }) {
  return (
    <div>
      <Hero setPage={setPage} />
      <About setPage={setPage} />
      <Testimonials />
      <Demo />
    </div>
  );
}

function App() {
  const [page, setPage] = useState("");

  useEffect(() => {
    // When the page changes, scroll to the top of the page
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <div>
      <Navbar setPage={setPage} />
      {page === "services" ? (
        <Services />
      ) : page === "contact" ? (
        <Contact />
      ) : (
        <Homepage setPage={setPage} />
      )}
      <Footer setPage={setPage} />
    </div>
  );
}

export default App;
