import React, { useEffect } from 'react'
import john from './images/john-doe.png'
import './About.css'
import AOS from "aos";
import "aos/dist/aos.css";

const About = ({setPage}) => {
    useEffect(() => {
      AOS.init();
    }, []);
    return (
      <div className="about" id="about">
        <div className="container">
          <img src={john} alt="cleaning" data-aos="fade-right"/>
          <div className="col-2" data-aos="fade-left">
            <h2>About</h2>
            <span className="line"></span>
            <p>
              Evershine Group is a Human Resources and facility management services based in Kochi. We have two more branches in Trivandrum and Calicut.
            </p>
            <p>Our services include Payroll Management, Contract Staffing and Facility Services</p>
            <button className="button" onClick={()=> {
              setPage("services")
            }}>Explore More</button>
          </div>
        </div>
      </div>
    );
}

export default About
