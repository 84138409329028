import React from "react";
import user1 from "./images/user1.png";
import user2 from "./images/user2.png";
import user3 from "./images/user3.png";
import user4 from "./images/user4.png";
import user5 from "./images/user5.png";
import user6 from "./images/user6.jpg";
import user7 from "./images/user7.png";
import user8 from "./images/user8.png";
import user9 from "./images/user9.png";
import user10 from "./images/user10.png";
import user11 from "./images/user11.png";
import user12 from "./images/user12.png";
import user13 from "./images/user13.png";
import user14 from "./images/user14.png";
import user15 from "./images/user15.jpg";
import "./Testimonials.css";
import Marquee from "react-fast-marquee";

const Testimonials = () => {
  const imageStyle = {
    width: "40%", // Adjusted width for better visibility
    height: "auto",
  };

  return (
    <div className="testimonials" id="testimonials">
      <div className="container">
        <h2>Our Clients</h2>
        <span className="line"></span>
        <Marquee
          pauseOnHover={true}
          speed={100}
          style={{ padding: "10px", margin: "50px 0px 10px 0px" }}
        >
          <div className="card">
            <img src={user1} style={imageStyle} alt="client logo" />
            <p>
              <span>Taj Group</span>
            </p>
          </div>
          <div className="card">
            <img src={user2} style={imageStyle} alt="client logo" />
            <p>
              <span>The Leela</span>
            </p>
          </div>
          <div className="card">
            <img src={user3} style={imageStyle} alt="client logo" />
            <p>
              <span>Raviz</span>
            </p>
          </div>
          <div className="card">
            <img src={user4} style={imageStyle} alt="client logo" />
            <p>
              <span>Sree Gokulam</span>
            </p>
          </div>
          <div className="card">
            <img src={user5} style={imageStyle} alt="client logo" />
            <p>
              <span>Mariott Hotels</span>
            </p>
          </div>
          <div className="card">
            <img src={user6} style={imageStyle} alt="client logo" />
            <p>
              <span>Four Points By Sheraton</span>
            </p>
          </div>
          <div className="card">
            <img src={user7} style={imageStyle} alt="client logo" />
            <p>
              <span>Estuary Sarovar Portico</span>
            </p>
          </div>
          <div className="card">
            <img src={user8} style={imageStyle} alt="client logo" />
            <p>
              <span>Lemeridien Kochi</span>
            </p>
          </div>
          <div className="card">
            <img src={user9} style={imageStyle} alt="client logo" />
            <p>
              <span>Paul Group</span>
            </p>
          </div>
          <div className="card">
            <img src={user10} style={imageStyle} alt="client logo" />
            <p>
              <span>Sterling Aniakatti</span>
            </p>
          </div>
          <div className="card">
            <img src={user11} style={imageStyle} alt="client logo" />
            <p>
              <span>SFS Trivandrum</span>
            </p>
          </div>
          <div className="card">
            <img src={user12} style={imageStyle} alt="client logo" />
            <p>
              <span>Backwater Ripples</span>
            </p>
          </div>
          <div className="card">
            <img src={user13} style={imageStyle} alt="client logo" />
            <p>
              <span>Tribute (Forum Mall)</span>
            </p>
          </div>
          <div className="card">
            <img src={user14} style={imageStyle} alt="client logo" />
            <p>
              <span>Skyline Foundations & Structures</span>
            </p>
          </div>
          <div className="card">
            <img src={user15} style={imageStyle} alt="client logo" />
            <p>
              <span>Getaway Beach Resorts</span>
            </p>
          </div>
        </Marquee>
        <Marquee
          pauseOnHover={true}
          speed={100}
          style={{ padding: "10px", margin: "10px" }}
          direction={"right"}
        >
          <div className="card">
            <img src={user15} style={imageStyle} alt="client logo" />
            <p>
              <span>Getaway Beach Resorts</span>
            </p>
          </div>
          <div className="card">
            <img src={user14} style={imageStyle} alt="client logo" />
            <p>
              <span>Skyline Foundations & Structures</span>
            </p>
          </div>
          <div className="card">
            <img src={user13} style={imageStyle} alt="client logo" />
            <p>
              <span>Tribute (Forum Mall)</span>
            </p>
          </div>
          <div className="card">
            <img src={user12} style={imageStyle} alt="client logo" />
            <p>
              <span>Backwater Ripples</span>
            </p>
          </div>
          <div className="card">
            <img src={user11} style={imageStyle} alt="client logo" />
            <p>
              <span>SFS Trivandrum</span>
            </p>
          </div>
          <div className="card">
            <img src={user10} style={imageStyle} alt="client logo" />
            <p>
              <span>Sterling Aniakatti</span>
            </p>
          </div>
          <div className="card">
            <img src={user9} style={imageStyle} alt="client logo" />
            <p>
              <span>Paul Group</span>
            </p>
          </div>
          <div className="card">
            <img src={user8} style={imageStyle} alt="client logo" />
            <p>
              <span>Lemeridien Kochi</span>
            </p>
          </div>
          <div className="card">
            <img src={user7} style={imageStyle} alt="client logo" />
            <p>
              <span>Estuary Sarovar Portico</span>
            </p>
          </div>
          <div className="card">
            <img src={user6} style={imageStyle} alt="client logo" />
            <p>
              <span>Four Points By Sheraton</span>
            </p>
          </div>
          <div className="card">
            <img src={user5} style={imageStyle} alt="client logo" />
            <p>
              <span>Mariott Hotels</span>
            </p>
          </div>
          <div className="card">
            <img src={user4} style={imageStyle} alt="client logo" />
            <p>
              <span>Sree Gokulam</span>
            </p>
          </div>
          <div className="card">
            <img src={user3} style={imageStyle} alt="client logo" />
            <p>
              <span>Raviz</span>
            </p>
          </div>
          <div className="card">
            <img src={user2} style={imageStyle} alt="client logo" />
            <p>
              <span>The Leela</span>
            </p>
          </div>
          <div className="card">
            <img src={user1} style={imageStyle} alt="client logo" />
            <p>
              <span>Taj Group</span>
            </p>
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export default Testimonials;
