import React from 'react'
import './Hero.css'

const Hero = ({setPage}) => {
    return (
        <div className='hero'>
            <div className='content'>
                <p>Call us</p>
                <p>9995991440</p>
                <p>Evershine Group</p>
                <p>HR and Facility Services</p>
                <button href='/' onClick={() => {
                    setPage("contact");
                }} className='button'>Contact Us</button>
            </div>
        </div>
    )
}

export default Hero
