import React, { useEffect } from 'react'
import './Demo.css'
import clean from "./images/clean.png";
import AOS from "aos";
import "aos/dist/aos.css";

const Demo = () => {
  useEffect(() => {
    AOS.init();
  }, []);
    return (
      <div className="demo_class" id="demo">
        <div className="container">
          <div className="col-1" data-aos="fade-right">
            <p>
              We have 500+ trained employees working accross different
              loccations in Kerala
            </p>
            <p>Evershine Group</p>
            <p>
              To give real service you must add something which cannot be bought
              or measured with money, and that is sincerity and integrity. A
              team with years of experience in Hr Management and Facility
              services.
            </p>
            <button className="button">
              Call now on 9995991440 for more details
            </button>
          </div>
          <div className="col-2" data-aos="fade-left">
            <img src={clean} width="100%" alt="cleaning"></img>
          </div>
        </div>
      </div>
    );
}

export default Demo
