import React, { useEffect } from "react";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import "./Footer.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Footer = ({ setPage }) => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="footer">
      <div className="container">
        <ul>
          <li className="nav-item">
            <a
              href="/"
              onClick={() => {
                setPage("homepage");
              }}
            >
              Home
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#about"
              onClick={() => {
                setPage("homepage");
              }}
            >
              About
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#services"
              onClick={() => {
                setPage("services");
              }}
            >
              Services
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#contact"
              onClick={() => {
                setPage("contact");
              }}
            >
              Contact Us
            </a>
          </li>
        </ul>
        <div className="contact-elements" data-aos="fade-up">
          <div>
            <div className="contact-item">
              <FaPhone className="icon" />
              <p>9895941375</p>
            </div>
            <div className="contact-item">
              <FaPhone className="icon" />
              <p>9895221375</p>
            </div>
            <div className="contact-item">
              <FaPhone className="icon" />
              <p>9895231375</p>
            </div>
          </div>
          <div className="address-line"></div>
          <div>
            <div className="contact-item">
              <FaEnvelope className="icon" />
              <p>evrshinegroup@gmail.com</p>
            </div>
            <div className="contact-item">
              <FaEnvelope className="icon" />
              <p>hr@evershinegroup.co.in</p>
            </div>
            <div className="contact-item">
              <FaEnvelope className="icon" />
              <p>bdm@evershinegroup.co.in</p>
            </div>
          </div>
          <div className="address-line"></div>
          <address className="address-width">
            <span className="office">Head Office</span> <br />
            Room No 283, 4th Floor, DD Vyapar Bhavan, Giringar Housing Colony,
            Kadavanthra, Kochi, Ernakulam, Kerala 682020
          </address>
          <div className="address-line"></div>
          <address className="address-width">
            <span className="office">Branch Office</span> <br />
            SYAMALA LINE, 1st Beach Road, Kovalam, Trivandrum - 695527
          </address>
        </div>
        <div className="bottom">
          <span className="line"></span>
          <p>2022 Evershine Group. All rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
